import React from "react";
import { MdOutlineArrowUpward } from "react-icons/md";

const Footer = () => {
  return (
    <div className="footer__wrapper">
      <a href="#landing" className="footer__logo">
        <h1 className="logo">
          <span className="theme__red">Red</span>
          wan.
        </h1>
        <span className="footer__scroll-top">
          Top <MdOutlineArrowUpward />
        </span>
      </a>
      <div className="footer__link--wrapper roboto">
        <div>
          <a href="https://github.com/devRedwan/">Github</a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/redwan-ahmed/">LinkedIn</a>
        </div>
        <div>
          <a href="/">Resume</a>
        </div>
      </div>
      <div className="roboto">Copyright &copy; 2023 Redwan Ahmed</div>
    </div>
  );
};

export default Footer;
