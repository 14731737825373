import { AnimatedHeader } from "./AnimateHeader";

function Project({ image, onClick, title }) {
  return (
    <div className="project" onClick={onClick}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <div className="project__description">
        <AnimatedHeader
          className={"project__description"}
          headerText={title}
          highlightedWords={[title]}
          highlightColor={"#DC143C"}
          color="#dc143c"
        />
      </div>

      <img src={image} alt="project-img" className="project__img" />
    </div>
  );
}

export default Project;
