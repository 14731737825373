import NftCoverImage from "../assets/project-images/NFT/nft-cover.png";
import NftFirstImage from "../assets/project-images/NFT/nft-desktop.png";
import NftFrontImage from "../assets/project-images/NFT/nft-front.png";
import NftSecondImage from "../assets/project-images/NFT/nft-phones-1.png";

import TreactCoverImage from "../assets/project-images/treact/treact-cover.png";
import TreactFirstImage from "../assets/project-images/treact/treact-desktop.png";
import TreactFrontImage from "../assets/project-images/treact/treact-front.png";
import TreactSecondImage from "../assets/project-images/treact/treact-phones-1.png";

import EcommerceCover from "../assets/project-images/ecommerce/ecommerce-cover.png";
import EcommerceFirstImage from "../assets/project-images/ecommerce/ecommerce-desktop.png";
import EcommerceFrontImage from "../assets/project-images/ecommerce/ecommerce-front.png";
import EcommerceSecondImage from "../assets/project-images/ecommerce/ecommerce-phones-1.png";

import { FaReact } from "react-icons/fa";
import { RiReactjsFill } from "react-icons/ri";
import {
  SiCss3,
  SiFirebase,
  SiJavascript,
  SiNextdotjs,
  SiTailwindcss,
  SiTypescript,
  SiVercel,
} from "react-icons/si";

import NextAuthIcon from "../assets/icons/nextauth.png";
import SanityIcon from "../assets/icons/sanity.png";

const projectsConfig = [
  {
    title: "BuyMyTime",
    type: "Online Marketplace",
    description:
      "Buy-my-time is a vibrant marketplace where professionals converge to offer their services and consumers find extra time in their busy lives. It's a win-win for everyone involved, redefining the concept of time management. Now, imagine doing your grocery shopping while concurrently standing on a long line to pick up your medicine - all from the comfort of your couch! With Buy-my-time, a day is no longer just 24 hours - it's a canvas of possibility, expanding the frontiers of your productivity.",
    techstack: [
      {
        icon: <SiTypescript />,
        text: "TypeScript",
        color: "rgb(45, 121, 199)",
      },
      {
        icon: <SiNextdotjs />,
        text: "NextJS",
        color: "rgb(0,0,0)",
      },
      {
        icon: <SiFirebase />,
        text: "Firebase",
        color: "rgb(246,130,13)",
      },
      {
        icon: <SiTailwindcss />,
        text: "Tailwind CSS",
        color: "rgba(56,189,249,255)",
      },
      {
        icon: <RiReactjsFill />,
        text: "React Context",
        color: "rgb(124, 197, 217)",
      },
      {
        icon: (
          <img
            src={SanityIcon}
            alt="Sanity Icon"
            className="project__techstack--image"
          />
        ),
        text: "Sanity.io",
        color: "rgba(240,74,57,255)",
      },
    ],
    liveLink: "https://buy-my-time.vercel.app/",
    githubLink: "https://github.com/devRedwan/buy-my-time",
    coverImage: NftCoverImage,
    topImage: NftFrontImage,
    firstImage: NftFirstImage,
    secondImage: NftSecondImage,
  },
  {
    title: "Twitter-Clone",
    type: "Social Networking",
    description:
      "Twitter-clone takes imitation to an unparalleled level, replicating the classic Twitter platform with such precision, it's virtually indistinguishable. Backed by robust CRUD functionality and nextAuth's secure protocols, it provides a seamless social media experience. Dive into trending topics, share your thoughts, or engage in vibrant conversations. With Twitter-clone, you're not just experiencing a replica, you're living the authentic Twitter experience, delivered with flawless fidelity.",
    techstack: [
      {
        icon: <SiNextdotjs />,
        text: "NextJS",
        color: "rgb(0,0,0)",
      },
      {
        icon: <SiTailwindcss />,
        text: "Tailwind CSS",
        color: "rgba(56,189,249,255)",
      },
      {
        icon: (
          <img
            src={SanityIcon}
            alt="Sanity Icon"
            className="project__techstack--image"
          />
        ),
        text: "Sanity.io",
        color: "rgba(240,74,57,255)",
      },
      {
        icon: (
          <img
            src={NextAuthIcon}
            alt="NextAuth Icon"
            className="project__techstack--image"
          />
        ),
        text: "NextAuth",
        color: "rgba(163,34,216,255)",
      },
      {
        icon: <SiVercel />,
        text: "Vercel",
        color: "rgba(0,0,0)",
      },
    ],
    liveLink: "https://twitter-media-clone.vercel.app/",
    githubLink: "https://github.com/devRedwan/twitter-media-clone",
    coverImage: TreactCoverImage,
    topImage: TreactFrontImage,
    firstImage: TreactFirstImage,
    secondImage: TreactSecondImage,
  },
  {
    title: "The Library",
    type: "React Ecommerce",
    description:
      "The online library at libray-app-react.vercel.app opens a new chapter in the realm of digital reading. A literary haven in the digital sphere, this platform delivers a smooth browsing experience, allowing you to effortlessly navigate through a rich collection of books from various genres. The interface is intuitive, making the hunt for your next read an enjoyable journey rather than a daunting task. It's as simple as a click to add your chosen books to the cart, paving the way for hassle-free purchases. Embrace the future of reading with this online library - a seamless amalgamation of literature and technology, making your reading experience more enjoyable and accessible than ever.",
    techstack: [
      {
        icon: <FaReact />,
        text: "React.js",
        color: "rgb(124, 197, 217)",
      },
      {
        icon: <SiCss3 />,
        text: "CSS",
        color: "rgb(41, 101, 241)",
      },
      {
        icon: <SiJavascript />,
        text: "JavaScript",
        color: "rgb(245, 222, 25)",
      },
      {
        icon: <SiVercel />,
        text: "Vercel",
        color: "rgba(0,0,0)",
      },
    ],
    liveLink: "https://libray-app-react.vercel.app/",
    githubLink: "https://github.com/devRedwan/librayApp-react",
    coverImage: EcommerceCover,
    topImage: EcommerceFrontImage,
    firstImage: EcommerceFirstImage,
    secondImage: EcommerceSecondImage,
  },
];

export default projectsConfig;
