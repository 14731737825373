import React from "react";
import Me from "../assets/me.jpeg";
import { FaDownload } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { AiFillLinkedin } from "react-icons/ai";
import { experienceConfig } from "../config/resume/experienceConfig";
import { technicalSkillsConfig } from "../config/resume/technicalSkillsConfig";
import { transferrableSkillsConfig } from "../config/resume/transferrableSkillsConfig";
import { AnimatedHeader } from "../components/AnimateHeader";

const Resume = () => {
  return (
    <div id="resume" className="container">
      <div
        className="projects__text-container"
        data-aos="fade-up"
        data-aos-delay="500">
        <AnimatedHeader
          className={"projects__header"}
          headerText={"Resume"}
          color="#000"
          glow={false}
        />
      </div>
      <div className="resume__wrapper">
        <div className="resume__grid" data-aos="fade-up" data-aos-delay="500">
          <div className="resume__image">
            <img src={Me} alt="" />
          </div>
          <div>
            <div className="resume__name raleway">Redwan Ahmed</div>
            <div className="resume__title roboto">Software Engineer</div>
            <div className="resume__contact--info">
              <div>
                <FaDownload color="#dc143c" />
                <a className="resume__link" href="/resume.pdf" target="_blank">
                  Download resume
                </a>
              </div>
              <div>
                <MdEmail color="#dc143c" />
                <a
                  className="resume__link"
                  href="mailto:contact@redwanahmed.com">
                  Email
                </a>
              </div>
              <div>
                <AiFillLinkedin color="#dc143c" />
                <a
                  className="resume__link"
                  href="https://www.linkedin.com/in/redwan-ahmed/"
                  target="_blank"
                  rel="noreferrer">
                  Linkedin
                </a>
              </div>
            </div>
            <div>
              As a quick-learning and adept Frontend Developer, I leverage
              modern technologies like React and NextJS to craft efficient,
              user-centric applications spanning eCommerce to social media,
              backed by a plethora of development tool mastery
            </div>
          </div>
        </div>

        <div className="resume__grid" data-aos="fade-up" data-aos-delay="500">
          <div className="resume__title--description">Experience</div>
          <div>
            {experienceConfig.map((experience, index) => (
              <div className="resume__job--wrapper" key={index}>
                <div className="resume__job">
                  <div>
                    <div className="resume__job--name">{experience.name}</div>
                    <div className="resume__job--description">
                      {experience.title}
                    </div>
                  </div>
                  <div className="resume__job--date">{experience.date}</div>
                </div>

                <ul className="resume__list-wrapper">
                  {experience.description.map((description, index) => (
                    <li key={index}>{description}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="resume__grid" data-aos="fade-up" data-aos-delay="500">
          <div className="resume__title--description">Technical Skills</div>
          <div>
            <div>
              <ul className="resume__list-wrapper resume__skills--list-wrapper">
                {technicalSkillsConfig.map((technicalSkills, index) => (
                  <li key={index}>{technicalSkills}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="resume__grid" data-aos="fade-up" data-aos-delay="500">
          <div className="resume__title--description">Transferrable Skills</div>
          <div>
            <div>
              <ul className="resume__list-wrapper resume__skills--list-wrapper">
                {transferrableSkillsConfig.map((transferrableSkills, index) => (
                  <li key={index}>{transferrableSkills}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div
          className="resume__grid resume__bottom"
          data-aos="fade-up"
          data-aos-delay="500">
          <div className="resume__title--description">References</div>
          <div>Available Upon request</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
