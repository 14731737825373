function About() {
  return (
    <div className="container" id="about">
      <div className="about__wrapper" data-aos="fade-down" data-aos-delay="50">
        <div className="about__left raleway">
          I'm a highly motivated{" "}
          <span className="theme__red">software engineer</span> with a strong
          passion for <span className="theme__red">website development</span>.
        </div>
        <div className="about__right roboto">
          Currently employed as a remote software engineer at{" "}
          <span className="theme__red">Chrono Group LLC</span>, I work on
          producing creative solutions to extremely difficult engineering
          problems every day.
          <br />
          <br />
          Continuously working towards improving my knowledge and skills to
          develop technologies that contribute to the betterment of society.
        </div>
      </div>
      {/* <img id="clouds" src={Clouds} alt="redwan-clouds" /> */}
    </div>
  );
}

export default About;
