import React, { useEffect, useRef } from "react";
import { BsChevronDoubleDown } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { HiOutlineCode, HiOutlineLink } from "react-icons/hi";
import { AnimatedHeader } from "./AnimateHeader";

function Modal({ isOpen, project, closeHandler }) {
  const modalRef = useRef(null);

  const handleScroll = () => {
    const scrollPosition = modalRef.current.scrollTop;
    modalRef.current.style.backgroundPosition = `center ${
      -scrollPosition * 0.1
    }px`;
  };

  useEffect(() => {
    const currentModal = modalRef.current;
    if (isOpen) {
      currentModal.addEventListener("scroll", handleScroll);
      return () => {
        currentModal.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isOpen]);

  if (isOpen) {
    return (
      <div className="modal__container" ref={modalRef}>
        <div className="modal__close" onClick={() => closeHandler()}>
          <CgClose />
        </div>
        <div className="modal__top--background">
          <div className="modal__top">
            <AnimatedHeader
              headerText={project.title}
              className="modal__title"
              highlightedWords={[project.title]}
              color="#DC143C"
              highlightColor="#DC143C"
            />
            <AnimatedHeader
              headerText={project.type}
              className="modal__type"
              color="#000"
              glow={false}
            />

            <div className="modal__top--image">
              <img src={project.topImage} alt="" />
            </div>
            <a
              className="modal__scroll-down"
              href="#modal__project--background">
              <BsChevronDoubleDown />
            </a>
          </div>
        </div>
        <div className="modal__grid--wrapper">
          <div
            className="modal__project--background"
            id="modal__project--background">
            <div className="modal__project--wrapper">
              <div className="modal__description">{project.description}</div>
              <div className="skills__button--wrapper">
                {project.techstack.map((item, index) => (
                  <div
                    key={index}
                    data-aos="fade-up"
                    data-aos-delay="300"
                    className={`skill__buttons`}
                    style={{ color: item.color }}
                  >
                    {item.icon}
                    <div className="skills__button--d-block">{item.text}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="modal__grid--left modal__grid">
            <div className="modal__grid--image">
              <img src={project.firstImage} alt="" />
            </div>
            <div className="modal__grid--linkButtons">
              <a
                target="_blank"
                rel="noreferrer"
                href={project.liveLink}
                className="modal__view-online--button raleway">
                View Live
                <HiOutlineLink />
              </a>
            </div>
          </div>
          <div className="modal__grid--right modal__grid">
            <div className="modal__grid--linkButtons">
              <a
                href={project.githubLink}
                target="_blank"
                rel="noreferrer"
                className="modal__view-online--button raleway">
                View Code <HiOutlineCode />
              </a>
            </div>
            <div className="modal__grid--image">
              <img src={project.secondImage} alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default Modal;
