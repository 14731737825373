export const technicalSkillsConfig = [
  "HTML/CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "NextJS",
  "Tailwind CSS",
  "Firebase",
  "Bootstrap",
];
