export const AnimatedHeader = ({
  headerText,
  className,
  highlightedWords = [],
  color = "#ffffff",
  highlightColor = null,
  glow = true,
}) => {
  let animationDelay = 200;
  return (
    <h1 className={className}>
      {headerText.split(" ").map((word, i) => {
        const isHighlighted = highlightedWords.includes(word);
        const wordWithSpace = `${word} `;
        return wordWithSpace.split("").map((letter, j) => {
          const key = `${i}-${j}`;
          if (letter === " ") {
            return <span key={key}>&nbsp;</span>;
          }
          const textColor =
            isHighlighted && highlightColor ? highlightColor : color;
          return (
            <span
              className={`rubberBand ${isHighlighted ? "highlight" : ""}`}
              key={key}
              data-aos="flip-left"
              data-aos-delay={`${(animationDelay = animationDelay + 100)}`}
              style={{
                color: textColor,
                textShadow: glow ? `${textColor} 0px 0px 30px` : "none",
              }}>
              {letter}
            </span>
          );
        });
      })}
    </h1>
  );
};
