export const experienceConfig = [
  {
    name: "Chrono Group, LLC",
    title: "Remote Frontend Developer",
    description: [
      "Assisted in developing various projects using AWS services (Route53, CloudFront, S3), and maintained 20+ web pages, contributing to the development, testing, and updates of 4 WordPress sites",
      "Led the creation and maintenance of front-end code for numerous eCommerce sites, managing site redesigns, seasonal updates, and production work",
      "Collaboratively resolved 200+ software bugs and conducted regular updates throughout production lifecycles, developing numerous user-facing features via reusable code and libraries",
      "Ensured the technical feasibility and optimization of 150+ UI/UX designs, resulting in maximized speed and scalability for 15+ applications",
    ],
    date: "Feb 2022 - Current",
  },
  {
    name: "PrayersConnect",
    title: "Remote Frontend Developer Intern",
    description: [
      "Created efficient programs by using dynamic data from multiple API endpoints to display the unique respective mosque info & prayer times from a database of over 2900 mosques",
      "Utilized TypeScript & NEXTJs to create interactive UI for managing information database for mosques around the country",
      "Developed UI Designs and dynamic web app elements using Typescript & MUI utilizing the NextJS Framework and authored test files from scratch with 99% coverage on all developed apps",
    ],
    date: "Sep 2021 - Dec 2021",
  },
  {
    name: "TalentTEK IT Training Center & Consulting",
    title: "Software QA Intern",
    description: [
      "Worked extensively with JIRA bug-tracking tool to manage projects and testing processes",
      "Built an automation testing framework with Selenium IDE, WebDriver, and TestNG and Log4j in Java & Python language using test scenarios created based on project requirements and user stories",
      "MySQL Server and API Testing with Swagger open-source framework to perform back-end testing",
      "Created a unique framework with TestNG in Selenium Webdriver to decrease coding time for test cases by 75%-85%",
    ],
    date: "Sep 2021 - Dec 2021",
  },
];
